/**=====================
     17. List CSS Start
==========================**/
.list-group-item.active {
	background-color: var(--theme-deafult);
	border-color: var(--theme-deafult);
}
.list-group{
	i{
		margin-right: $list-group-margin;
	}
	img{
		height: 40px;
		width: 40px;
		margin-right: $list-group-margin;
	}
}

#list-tab {
	li {
		&:first-child {
			.list-group-item {
				border-top-right-radius: 0.25rem;
				border-top-left-radius: 0.25rem;
			}
		}
		&:last-child {
			.list-group-item {
				border-bottom-right-radius: 0.25rem;
				border-bottom-left-radius: 0.25rem;
			}
		}
		+ li {
			.list-group-item {
				border-top: none;
			}
		}
	}
}

.vertical-tab {
	.nav-tabs {
		display: inline-block;
		width: 28%;
		margin-right: 2%;
		margin-bottom: 0;
		.nav-item {
			border: 1px solid rgba($black, 0.125);
			+ .nav-item {
				border-top: none;
			}
			&:last-child {
				border-bottom: none;
				.nav-link {
					border-bottom-left-radius: 4px;
					border-bottom-right-radius: 4px;
				}
			}
		}
		.nav-link {
			padding: 0.75rem 1.25rem;
			color: $theme-body-font-color;
			border-bottom: none;
			&.active {
				color: $white;
				background-color: var(--theme-deafult);
			}
		}
	}
	.tab-content {
		display: inline-block;
		width: 70%;
		vertical-align: top;
	}
}
/**=====================
     17. List CSS Ends
==========================**/